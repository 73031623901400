import React from 'react';
import type { ComponentProps } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import EditionsIcon from '@atlaskit/icon-lab/core/editions';

import {
	GRADIENT_CTA_BUTTON_DEFAULT_START,
	GRADIENT_CTA_BUTTON_DEFAULT_END,
	GRADIENT_CTA_BUTTON_ACTIVE_END,
	GRADIENT_CTA_BUTTON_ACTIVE_START,
	GRADIENT_CTA_BUTTON_HOVER_END,
	GRADIENT_CTA_BUTTON_HOVER_START,
} from './GradientConstants';
import { ButtonBase } from './GradientCTAButtonBase';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(ButtonBase)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
		borderRadius: `${token('border.radius.100')}`,

		background: `linear-gradient(45deg,
    ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
      ${GRADIENT_CTA_BUTTON_DEFAULT_START} 0%,
    ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
      ${GRADIENT_CTA_BUTTON_DEFAULT_END} 100%)`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&, &:active, &:visited, &:hover, &:focus': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.inverse')} !important`,
		},
		'&:hover': {
			cursor: 'pointer',

			background: `linear-gradient(45deg,
      ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
          ${GRADIENT_CTA_BUTTON_HOVER_START} 0%,
      ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
          ${GRADIENT_CTA_BUTTON_HOVER_END} 100%)`,
		},

		'&:active': {
			cursor: 'pointer',

			background: `linear-gradient(45deg,
      ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
          ${GRADIENT_CTA_BUTTON_ACTIVE_START} 0%,
      ${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
          ${GRADIENT_CTA_BUTTON_ACTIVE_END} 100%)`,
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& span': {
			display: 'inline-flex',
			margin: 0,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonText = styled.span({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
});

/**
 * A styled button with a gradient background, intended to be used as a product edition upgrade CTA button.
 */
export const GradientCTAButton = ({
	children,
	...buttonProps
}: ComponentProps<typeof StyledButton>) => {
	return (
		<StyledButton iconBefore={<EditionsIcon label="" />} {...buttonProps}>
			<ButtonText>{children}</ButtonText>
		</StyledButton>
	);
};
